export default class ProductEdit {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}
