export default class ForSaleSwitches {

  constructor() {
    this.bindEvents();
    //window.application.setOnDataChangeListener(this);
  }

  bindEvents() {
    let self = this;
    
    $(".js-switch").each((index, element) => {
      var obj_type = $(element).data("type");
      var id = $(element).data("id");
      var value = $(element).data("value");
      
      $(element).unbind("change").change(function(e) {
        self.toggle_permissions(id, value, $(e.target).is(':checked') ? '1' : '0', obj_type);
      });
    });    
  }


  onDataChanged(data) {

  }

  onDestroy() {
    $(".js-switch").unbind("change");
  }

  toggle_permissions(id, file_name, value, obj_name = 'product') {
    let data = {};
    let field_value = {};
    field_value[file_name] = value
    data[obj_name] = field_value

    var controller_name = obj_name + "s"

    let ajax_data = {
      url: "/" + controller_name + "/" + id + "/ajax_update",
      
      data: data,
      method: "put",
      success: function (data){
        application.successNotification(I18n.t("backoffice."+controller_name+".update.success"))
      },
      error: function (err){
        application.errorNotification(I18n.t("backoffice."+controller_name+".update.error"))
      }
    }

    $.ajax(ajax_data)
  }
}