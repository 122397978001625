import ForSaleSwitches from "../utils/ForSaleSwitches";

export default class OptionIndex {

    constructor() {
        this.switches = new ForSaleSwitches();
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.switches.onDestroy();
    }
}