import L from "leaflet";
import "leaflet/dist/leaflet.css"


export default class DashboardIndex {

    constructor() {
        this.map = null;
        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.map = L.map('map').setView([46.5841686,0.9337407], 6.16);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);

    }

    onDataChanged(data) {
        this.populateMap(data.id);
    }

    onDestroy() {
    }

    populateMap(id){
        let self = this;

        $.ajax({
            url: "/dashboard/"+id+"/ajax_test_markers",
            method: "GET"
        }).done(function (data){

            let greenIcon = L.icon({
                iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
                shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',

                iconSize:     [38, 95], // size of the icon
                shadowSize:   [50, 64], // size of the shadow
                iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
            });
            data.forEach(function (item){
                L.marker(item, {icon: greenIcon}).addTo(self.map);
            })
        })
    }
}