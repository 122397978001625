import ForSaleSwitches from "../utils/ForSaleSwitches";

export default class BackofficeEntityOptionsIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      let self = this;
      
      $(".js-switch").each((index, element) => {
        var id = $(element).data("id");
        var entityId = $(element).data("entity-id");
        var isEntityoption = $(element).data("is-entity-option");

        $(element).unbind("change").change(function(e) {
          self.toggle_permissions(id, $(e.target).is(':checked') ? '1' : '0', entityId, isEntityoption);
        });
      });    
    }
  
    onDataChanged(data) {
  
    }
  
    onDestroy() {
      $(".js-switch").unbind("change");
    }
  
    toggle_permissions(id, value, entityId, isEntityoption) {
      let data = {};
      let field_value = {};
      field_value["for_sale"] = value
      data["entity_option"] = field_value
      data["is_entity_option"] = isEntityoption
  
      let ajax_data = {
        url: "/entities/" + entityId + "/entity_options/" + id + "/ajax_update",
        data: data,
        method: "put"
      }
  
      $.ajax(ajax_data)
    }
}