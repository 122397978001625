export default class BackofficeOrdersShow {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".order-edit").on('click', event => {
      const orderItemId = $(event.currentTarget).data('order-item');
      const OrderId = $(event.currentTarget).data('order');
      this.launchOrderEditModal(OrderId, orderItemId);
    });

    $("#order-form-submit").on('click', event => {
      this.submitOrderForm();
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }

  launchOrderEditModal(orderId, orderItemId) {
    let url = "/orders/" + orderId + "/ajax_order_item_form/" + orderItemId;

    $.ajax({
      url: url,
      type: "GET",
      success: (data) => {
        $("#order-edit-modal-body").html(data);
        $("#order-edit-modal").modal("show");
      }
    });
  }

  submitOrderForm() {
    console.log("submitOrderForm");
    let form = $("#order-form");
    form.trigger('submit.rails'); 
  }
}