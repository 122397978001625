export default class AdminsIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
      $(".teams-select").select2({
        placeholder: "Choix des équipes",
        allowClear: true,
        closeOnSelect: false
      })
      
      $(".teams-select").on('change', (e) => {
        this.updateAdminsTeams(e)
      })
    }

    updateAdminsTeams(element) {
      let teams = $(element.target).val()
      let url = $(element.target).data('url')

      $.ajax({
        url: url,
        type: 'PUT',
        data: { team_ids: teams },
        success: (data) => {
          application.successNotification("Affectation effectuée avec succès")
        },
      })
    }

    onDataChanged(data) {
    }

    onDestroy() {
      $(".teams-select").select2('destroy')
    }
}