import I18n from 'i18n-js';
import TaskAssignation from '../utils/TaskAssignation';

export default class BackofficeTasksShow {
  constructor() {
    this.bindEvents();
    this.initDropify();
    this.taskAssignation = new TaskAssignation();
  }

  bindEvents() {
    $(".show-comments").on('click', this.showComments);
    $(".show-files").on('click', this.showFiles);

    $(".comment-visibility-checkbox").on('change', this.changeItemVisibility);

    $(".show-file-btn").on('click', this.showFileModal);

    $("#process-laucher").on('click', this.launchProcessModal);
  }

  showComments() {
    $("#comments-section").show();
    $("#files-section").hide();

    $(".show-comments").addClass("underline");
    $(".show-files").removeClass("underline")
  }

  showFiles() {
    $("#comments-section").hide()
    $("#files-section").show()

    $(".show-comments").removeClass("underline")
    $(".show-files").addClass("underline")
  }

  showFileModal() {
    let title = $(this).data('file-title');
    let url = $(this).data('file-url');

    $("#file-modal-title").text(title);
    
    $("#embed-wrapper").empty();
    if (url.endsWith('.pdf')) {
      $("#embed-wrapper").append(`<embed src="${url}" type="application/pdf" width="100%" height="600px" />`);
    } else if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png')) {
      $("#embed-wrapper").append(`<img src="${url}" class="img-fluid" />`);
    } else {
      $("#embed-wrapper").append(`<iframe src="${url}" width="100%" height="600px" />`);
    }

    $("#download-button").attr('href', url);
    $("#file-modal").modal('show'); 
  }

  changeItemVisibility() {
    const attachment_id = $(this).data('attachment-id');
    const item_type = $(this).data('item-type');
    const visibility = $(this).is(':checked');
    const url = $(this).data('url');

    $.ajax({
      url: url,
      method: 'PUT',
      data: { 
        item: {
          visibility: visibility,
          item_type: item_type,
          id: attachment_id
        }
      },
      success: function(data) {
        application.successNotification(I18n.t("backoffice.tasks.update_visibility_success"))
      },
      error: function(data) {
        application.errorNotification(I18n.t('backoffice.tasks.update_visibility_error'));
      }
    });
  }

  initDropify() {
    $(".dropify").dropify({
      messages: {
      'default': I18n.t('plugins.dropify.messages.default'),
      'replace': I18n.t('plugins.dropify.messages.replace'),
      'remove': I18n.t('plugins.dropify.messages.remove'),
      'error': I18n.t('plugins.dropify.messages.error')
      },
      error: {
      'fileSize': I18n.t('plugins.dropify.error.fileSize', { value: '2MB' }),
      'minWidth': I18n.t('plugins.dropify.error.minWidth'),
      'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
      'minHeight': I18n.t('plugins.dropify.error.minHeight'),
      'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
      'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
      'fileExtension': I18n.t('plugins.dropify.error.fileExtension'),
    }
    });
  }

  launchProcessModal() {
    const taskId = $(this).data('task');

    
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $(".dropify").dropify("destroy");
  }
}