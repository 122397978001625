
export default class BackofficeOrdersIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      this.table = $("#orders-datatable").DataTable({
        "responsive": true,
        "searching": true,
        "columnDefs": [{
          "targets": -1,
          "orderable": false
        }],
        "order": [[2, "desc"]],
        "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right>"
        }
      });
  
    }
  
    onDataChanged(data) {
  
    }
  
    onDestroy() {
      this.table.destroy();
    }
}