export default class InvoiceIndex {

    constructor() {
        this.datatable = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);

        this.initDatatable();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.datatable.destroy();
    }

    initDatatable() {
        let $el = $('#invoice_datatable');
        this.datatable = $el.DataTable({
            dom: 'rtp',
            responsive: false,
            serverSide: true,
            "pageLength": 20,
            order: [[0, 'desc']],
            language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 }
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "actions", "orderable": false },
            ]
        })
    }
}