import TemplateValidation from "../../extranet/utils/TemplateValidation";

export default class EligibilityRequestsIndex {

    constructor() {
        this.datatable = null;
        this.exportValidation = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
        this.init_all_datatable();
        this.init_ftto_datatable();
        this.init_fon_datatable();

        this.initValidation();
    }


    bindEvents() {
        $(".js-export-csv").click( this.onExport )
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.datatable.destroy();
        this.dataTable = null;

        if(this.exportValidation !== null){
            this.exportValidation.onDestroy();
            this.exportValidation = null;
        }
    }

    init_all_datatable(){
        let $el = $('#all_datatable')
        let self = this;
        if($el.length > 0)
        this.datatable = $el.DataTable({
            "responsive": false,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "serverSide": true,
            "pageLength": 20,
            "lengthMenu": [
                [25, 50, 100, 1000],
                [25, 50, 100, 1000]
            ],
                dom: 'Bfrtip',
                buttons: [
                    {
                        text: 'CSV',
                        action: function (e, dt, node, config) {
                            self.openCsvModal('all')
                        }
                    },
                    'pageLength'
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "uuid", "class": "code-font" },
                { "data": "address" },
                { "data": "postal_code" },
                { "data": "city" },
                { "data": "latitude" },
                { "data": "longitude" },
                { "data": "secondary_address" },
                { "data": "secondary_postal_code" },
                { "data": "secondary_city" },
                { "data": "secondary_latitude" },
                { "data": "secondary_longitude" },
                { "data": "result" },
                { "data": "result_one" },
                { "data": "request_type", "orderable": false },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });
    }

    init_ftto_datatable(){
        let $el = $('#ftto_datatable')
        let self = this;
        if($el.length > 0)
        this.datatable = $el.DataTable({
            "responsive": false,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "serverSide": true,
            "pageLength": 20,
            "lengthMenu": [
                [25, 50, 100, 1000],
                [25, 50, 100, 1000]
            ],
                dom: 'Bfrtip',
                buttons: [
                    {
                        text: 'CSV',
                        action: function (e, dt, node, config) {
                            self.openCsvModal('ftto')
                        }
                    },
                    'pageLength'
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "uuid", "class": "code-font" },
                { "data": "address" },
                { "data": "postal_code" },
                { "data": "city" },
                { "data": "latitude" },
                { "data": "longitude" },
                { "data": "result" },
                { "data": "result_one" },
                { "data": "request_type", "orderable": false },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });
    }

    init_fon_datatable(){
        let $el = $('#fon_datatable')
        let self = this;
        if($el.length > 0)
        this.datatable = $el.DataTable({
            "responsive": false,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "serverSide": true,
            "pageLength": 20,
            "lengthMenu": [
                [25, 50, 100, 1000],
                [25, 50, 100, 1000]
            ],
                dom: 'Bfrtip',
                buttons: [
                    {
                        text: 'CSV',
                        action: function (e, dt, node, config) {
                            self.openCsvModal('fon')
                        }
                    },
                    'pageLength'
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "uuid", "class": "code-font" },
                { "data": "address" },
                { "data": "postal_code" },
                { "data": "city" },
                { "data": "latitude" },
                { "data": "longitude" },
                { "data": "secondary_address" },
                { "data": "secondary_postal_code" },
                { "data": "secondary_city" },
                { "data": "secondary_latitude" },
                { "data": "secondary_longitude" },
                { "data": "result" },
                { "data": "request_type", "orderable": false },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });
    }

    openCsvModal(type) {
        $('#export_csv_type').val(type)
        $('#export_csv_modal').modal('show')
    }

    onExport(e){
        e.preventDefault();
        let $el = $(e.currentTarget)
        let self = application.current_handler;

        if(!$("#export_csv_form").valid()){ return false; }

        $("#export_csv_modal").modal("hide")

        self.exportRequests(
            $el.data("url"),
            $("#export_csv_type").val(),
            $("#export_csv_start_date").val(),
            $("#export_csv_end_date").val()
        );
    }

    exportRequests(url, type, start_date, end_date){
        application.openLoader();

        $.ajax({
            url: url,
            method: "POST",
            data: {
                type: type,
                start_date: start_date,
                end_date: end_date
            },
            success: function (data){
                application.closeLoader();
                window.open(data.url, '_blank')
            },
            error: () => {
                application.closeLoader();
            }
        })
    }

    initValidation() {
        this.exportValidation = new TemplateValidation(this.validationExportData(), "#export_csv_form");
    }

    validationExportData() {
        return {
            "export_csv_start_date": {
                required: true
            },
            "export_csv_end_date": {
                required: true,
                greater_than: "#export_csv_start_date"
            }
        };
    }
}