import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";
import EligibilityRequestsIndex from "../controllers/EligibilityRequestsIndex";
import InvoiceIndex from "../controllers/InvoiceIndex";
import InvoiceForm from "../controllers/InvoiceForm";
import SharedFileForm from "../controllers/SharedFileForm";
import DownloadablesForm from "../controllers/DownloadablesForm";
import ProductNew from "../controllers/ProductNew";
import ProductIndex from "../controllers/ProductIndex";
import ProductEdit from "../controllers/ProductEdit";
import OptionIndex from "../controllers/OptionIndex";
import BackofficeEntityProductsIndex from "../controllers/BackofficeEntityProductsIndex";
import BackofficeEntityOptionsIndex from "../controllers/BackofficeEntityOptionsIndex";
import BackofficeOrdersShow from "../controllers/BackofficeOrdersShow";
import BackofficeTasksShow from "../controllers/BackofficeTasksShow";
import BackofficeTasksIndex from "../controllers/BackofficeTasksIndex";
import BackofficeOrdersIndex from "../controllers/BackofficeOrdersIndex";
import BackofficeStudyRequestsIndex from "../controllers/BackofficeStudyRequestsIndex";

export default class Routes extends ConfigRoutes{

    static routes = {
        //"DashboardIndex": DashboardIndex,
        "BackofficeBackofficeDashboardTestMarkers": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        // Admin Menus
        "BackofficeAdminMenusIndex": AdminMenuIndex,
        "BackofficeAdminMenusNew": AdminMenuForm,
        "BackofficeAdminMenusCreate": AdminMenuForm,
        "BackofficeAdminMenusEdit": AdminMenuForm,
        "BackofficeAdminMenusUpdate": AdminMenuForm,
        // Eligibility Requests
        "BackofficeEligibilityRequestsIndex": EligibilityRequestsIndex,
        "BackofficeEligibilityRequestsFtto": EligibilityRequestsIndex,
        "BackofficeEligibilityRequestsFon": EligibilityRequestsIndex,
        // Invoices
        "BackofficeInvoicesIndex": InvoiceIndex,
        "BackofficeInvoicesEdit": InvoiceForm,
        "BackofficeInvoicesNew": InvoiceForm,
        "BackofficeInvoicesUpdate": InvoiceForm,
        "BackofficeInvoicesCreate": InvoiceForm,
        // Downloadables
        "BackofficeDownloadablesNew": DownloadablesForm,
        "BackofficeDownloadablesEdit": DownloadablesForm,
        "BackofficeDownloadablesUpdate": DownloadablesForm,
        "BackofficeDownloadablesCreate": DownloadablesForm,
        // Products
        "BackofficeProductsNew": ProductNew,
        "BackofficeProductsEdit": ProductEdit,
        "BackofficeProductsIndex": ProductIndex,
        // Options
        "BackofficeOptionsIndex": OptionIndex,
        "BackofficeOptionsEdit": OptionIndex,
        // Entity Products
        "BackofficeEntityProductsIndex": BackofficeEntityProductsIndex,
        // Entity Options
        "BackofficeEntityOptionsIndex": BackofficeEntityOptionsIndex,
        // BO Orders
        "BackofficeOrdersShow": BackofficeOrdersShow,
        "BackofficeOrdersIndex": BackofficeOrdersIndex,
        // BO Tasks
        "BackofficeTasksShow": BackofficeTasksShow,
        "BackofficeTasksOrderTasks": BackofficeTasksIndex,
        "BackofficeTasksStudyRequestsTasks": BackofficeTasksIndex,
        "BackofficeTasksClosedTasks": BackofficeTasksIndex,
        // BO shared files (like invoices)
        "BackofficeSharedFilesNew": SharedFileForm,
        "BackofficeSharedFilesEdit": SharedFileForm,
        "BackofficeSharedFilesUpdate": SharedFileForm,
        "BackofficeSharedFilesCreate": SharedFileForm,

        // Study Requests
        "BackofficeStudyRequestsIndex": BackofficeStudyRequestsIndex,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}