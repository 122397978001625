export default class TaskAssignation {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".assignation-btn").on('click', this.showAssignationModal);
    $("#assignation-modal-save").on('click', this.saveAssignation);
  }

  showAssignationModal() {
    const url = $(this).data('url');

    $.ajax({
      url: url,
      method: 'GET',
      success: function(data) {
        $("#assignation-modal-body").html(data);
        $("#assignation-modal").modal('show');
      }
    });
  }

  saveAssignation() {
    let form = $("#assignation-form");
    form.trigger('submit.rails');
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }
}